



























































import { Vue, Component } from "vue-property-decorator";
import { AuthData } from "@/store/auth/types";
import { RawLocation } from "vue-router";

interface MenuItem {
  icon: string;
  title: string;
  route: RawLocation;
  exact: boolean;
}

@Component
export default class StudentLayout extends Vue {
  drawer = null;
  title = "";
  currentMenuItem = {};
  menuItems: MenuItem[] = [
    {
      icon: "mdi-monitor-dashboard",
      title: "Eğitimlerim",
      route: { name: "MyCourses" },
      exact: true
    },
    // {
    //   icon: "mdi-star-check",
    //   title: "Ön Başvurularım",
    //   route: { name: "UserPreApps" },
    //   exact: true
    // },
    {
      icon: "mdi-account-details",
      title: "Siparişlerim",
      route: { name: "MyOrders" },
      exact: true
    },
    // {
    //   icon: "mdi-account-edit",
    //   title: "Profilim",
    //   route: { name: "UserProfile" },
    //   exact: true
    // },
    // {
    //   icon: "mdi-format-list-text",
    //   title: "Eğitim Kataloğu",
    //   route: { name: "CourseCatalog" },
    //   exact: false
    // }
    {
      icon: "mdi-arrow-left",
      title: "Ana Sayfaya Dön",
      route: { name: "Home" },
      exact: true
    }
  ];

  goTo(item: MenuItem) {
    this.currentMenuItem = item;
    this.title = item.title;
    this.$router.push(item.route).catch(() => {
      return false;
    });
  }

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  logout(): void {
    this.$store.dispatch("auth/logout");
    this.$router.push({ name: "Home" }).catch(() => {
      return false;
    });
  }
}
